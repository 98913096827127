import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Columns } from "../components/Containers"
import Seamless from "../components/Seamless"

const CEPage = () => (
  <Layout navSpacer language="en">
    <SEO title="Continuing Education RSVP" description="" />

    <Section>
      <Columns sideColumnsSize={5}>
        <div className="column">
          <h3 className="has-text-centered mb-3">Dental Hygiene Continuing Education</h3>
          <p>
            <b>Location</b>:<br />
            <a
              href="https://www.google.com/maps/place/Wildwood+Country+Club/@38.1745463,-85.6203131,17z/data=!3m1!4b1!4m6!3m5!1s0x88690a6cecf7931d:0x33335166d42b1e46!8m2!3d38.1745463!4d-85.6177382!16s%2Fg%2F1tgz66mf?entry=ttu"
              target="_blank">
              Wildwood Country Club
              <br />
              5000 Bardstown Rd, <br />
              Louisville, KY 40291
            </a>
          </p>
          <h4 className="bold mb-2 has-text-centered">
            Space is Limited. Please RSVP by September 5th.
          </h4>
          <p>
            <b>Date</b>:<br />
            Thursday, September 19th
          </p>

          <p>
            <b>Time</b>:<br />
            Registration: 6:00 p.m. - 6:30 p.m.
            <br />
            Dinner and Lecture: 6:30 p.m. - 8:30 p.m.
          </p>
          <p>
            <b>2 CE Credits</b>
          </p>
          <p>
            <b>Hosted by</b>: <br />
            Innovative Oral Surgery & Dental Implant Studio
            <br />
            Dr. Kenneth Livesay, Jr., Dr. Austin Carey, & Dr. David Naselsker{" "}
          </p>
          <p>
            <b>Sponsored by</b>: <br />
            Straumann – Jon Homer
          </p>
          <p>
            <b>Contact</b>: <br />
            If you have any questions about the evening, please contact: <br />
            <b className="block mb-0 mt-1">Paula Harlamert</b>
            <ul>
              <li>
                <a href="tel:+15026713092">502-671-3092</a>
              </li>
              <li>
                <a href="mailto:paula@eastlouisvilleoralsurgery.com">
                  paula@eastlouisvilleoralsurgery.com
                </a>
              </li>
            </ul>{" "}
            <br />
            <b className="block mb-0">Morgan Camp</b>
            <ul>
              <li>
                <a href="tel:+15023875410">502-387-5410</a>
              </li>
              <li>
                <a href="mailto:morgan@eastlouisvilleoralsurgery.com">
                  morgan@eastlouisvilleoralsurgery.com
                </a>
              </li>
            </ul>
          </p>
          <div className="mt-3" style={{ maxWidth: 900, marginInline: "auto" }}>
            <Seamless
              height="1200"
              src="https://secureform.seamlessdocs.com/f/914da27cb85cb82e7270565c85d9d459?embedded=true"
            />
          </div>
        </div>
      </Columns>
    </Section>
  </Layout>
)

export default CEPage
